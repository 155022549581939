import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import PageHeader from "../components/pageheader"
import logo from "../images/eamn-icon.png"
import FluidImage from "../components/FluidImage"

const TEAM_QUERY = graphql`
  query GET_TEAM_MEMBERS {
    wpgraphql {
      teamMembers {
        nodes {
          id
          title(format: RENDERED)
          featuredImage {
            altText
            sourceUrl
            imageFile {
              publicURL
            }
          }
          profileFields {
            bio
            email
            phoneNumber
            role
          }
        }
      }
    }
  }
`

const Team = props => {
  return (
    <StaticQuery
      query={TEAM_QUERY}
      render={({
        wpgraphql: {
          teamMembers: { nodes: team },
        },
      }) => {
        // console.log(team)
        // const InfoD = teamMember => {
        //   if(teamMember.profileFields.phoneNumber || teamMember.profileFields.email == null) {
        //   <p className="hidesection"></p>
        // } else {
        //   <p className="infod">{teamMember.profileFields.phoneNumber} | {teamMember.profileFields.email}</p>
        // }
        return (
          <Layout>
            <PageHeader
              subTitle="Equity Alliance MN"
              headingTitle="Our Team"
              theme="about"
            />
            <div className="container">
              <div className="team-container">
                {team.map(teamMember => (
                  <div
                    key={teamMember.id}
                    className="person-container slide-in-blurred-top"
                  >
                    <div className="team-image">
                      <div className="image-frame">
                        {/*<FluidImage src={teamMember.profileFields.imageUrl} style={{ marginBottom: "15px" }} />*/}
                        <img
                          src={teamMember.featuredImage.imageFile.publicURL}
                          className=""
                          alt={teamMember.featuredImage.altText}
                        />
                      </div>
                    </div>
                    <div className="team-info">
                      <h4><div dangerouslySetInnerHTML={{ __html: teamMember.title }}></div></h4>
                      <h5>{teamMember.profileFields.role}</h5>
                      <hr className="tm-hr" />
                      {teamMember.profileFields.phoneNumber === null ? (
                        <p className="hide"></p>
                      ) : (
                        <div className="info-info">
                          <div className="info-phone">
                            {teamMember.profileFields.phoneNumber}
                          </div>
                          <div className="info-email">
                            {teamMember.profileFields.email}
                          </div>
                        </div>
                      )}
                      {/*<p>{teamMember.profileFields.phoneNumber} - {teamMember.profileFields.email}</p>*/}
                      {/*<InfoD/>*/}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: teamMember.profileFields.bio,
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Layout>
        )
      }}
    />
  )
}

export default Team
